body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
/*HeaderBar*/
.trigger{
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
}
.trigger:hover{
  color: #1890ff;
}
.header-ul{
  display: flex;
  width: 200px;
}
.header-ul li {
  flex-grow: 1;
  text-align: center;
}
.header-ul>li img{
  width: 35px;
  height: 35px;
  border-radius: 100px;
}
.menu{
  line-height: 2em;
  padding: 0 10px;
}
.menu-group li{
  padding: 0 24px;
  line-height: 2em;
}

.card-item{
  margin-bottom: 10px;
  border-radius: 3px;
  line-height: 2em;
}
.card-ul{
  list-style: inside circle;
}

/*GalleryDemo*/
.aspect {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  overflow: hidden;
}
.aspect--1x2{
  padding-bottom: calc(112.5% + 8px);
}
.aspect--1x1 {
  padding-bottom: 56.25%;
  /*padding的百分比是按父级的width计算的*/
}
.aspect--2x1 {
  padding-bottom: 28.125%;
}
.aspect__inner{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical-center-modal .ant-modal {
  top: 0;
}

/*美化webkit内核滚动条*/
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
::-webkit-scrollbar-thumb {
  background-color: #777;
}

/*更改antd的primary button样式*/
/* .ant-upload-picture-card-wrapper{
  display: flex;
} */
.ant-upload-list, .ant-upload-list-text：{
  display: block!important;
}
.ant-upload-picture-card-wrapper{
  display: contents!important;
}