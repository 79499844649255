/* latin-ext */
@font-face {
    font-family: 'Black Ops One';
    font-style: normal;
    font-weight: 400;
    src: local('Black Ops One Regular'), local('BlackOpsOne-Regular'), url(https://fonts.gstatic.com/s/blackopsone/v9/qWcsB6-ypo7xBdr6Xshe96H3aDXbtxsis4IxXw.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Black Ops One';
    font-style: normal;
    font-weight: 400;
    src: local('Black Ops One Regular'), local('BlackOpsOne-Regular'), url(https://fonts.gstatic.com/s/blackopsone/v9/qWcsB6-ypo7xBdr6Xshe96H3aDvbtxsis4I.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.error404 {
    background: #e6e6e6;
    font-weight: bold;
    font-size: 1em;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-family: 'Black Ops One', cursive;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.error404 canvas {
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.error404 .text .wrap {
    fill: #FFF;
}

.error404 .overlay {
    height: 0;
    width: 0;
    overflow: hidden;
    position: absolute;
}
.error404 .overlay .txt {
    font-size: 14rem;
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: -.5rem;
    text-shadow: 0 -3px 0 #0d0d0d, 0 6px 8px rgba(13, 13, 13, 0.55), 0 9px 10px rgba(13, 13, 13, 0.25);
}
.error404 .overlay .txt2 {
    font-size: 4rem;
}

.error404 section {
    align-self: center;
}

.error404 h1 {
    position: relative;
    font-size: 8em;
    font-weight: bold;
    line-height: 1;
    display: inline-block;
    width: 900px;
    height: 400px;
}
.error404 h1 .fill {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.error404 h1 .inv {
    position: absolute;
    width: 900px;
    height: 400px;
    top: 0;
    left: 0;
}
.error404 h1 .rect {
    fill: #e6e6e6;
}
.error404 h1 .clear {
    fill: transparent;
}