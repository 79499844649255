@import 'styles/helpers';
@import 'index';

.basicLayout {
  display: flex;
  min-height: 100vh;
  overflow-x: hidden;
  &-content {
    flex: 1;
    width: calc(100% - 256px);
    display: flex;
    flex-direction: column;
    background: $layout-body-background;
  }
  &-mainContent {
    flex: 1;
    margin: 0px 24px;
  }
  &-header {
    width: 100%;
    height: 64px;
    background: $grey-1;
    box-shadow: $shadow-1-down-light;
    display: flex;
    justify-content: flex-end;
    padding-right: 12px;
    z-index: $z-index-1;
  }
  &-avatarContainer {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 12px;
    &:hover {
      cursor: pointer;
      background: $blue-1;
    }
  }
  &-avatar {
    background-color: $blue-4;
  }
  &-notice {
    display: flex;
    align-items: center;
    padding: 0 12px;
    cursor: pointer;
    &:hover {
      background: $blue-1;
    }
  }
  &-topText{
    font-size: 15px;
    padding-bottom: 12px;
    font-weight: bolder;
    border-bottom: 2px solid #e6e6e6
  }
  &-noticeIcon {
    font-size: $font-size-lg;
  }
  &-noticeHeaderBtn{
     text-align: left;
     height: 35px;
     margin: 5px;
  }
  &-noticeItem {
    padding: 12px 25px;
    word-break: break-all;
    border:1px dashed #e3e3e3;
    border-radius: 5px;
    margin:12px;
    position: relative;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &-noticeBox{
    max-height: 420px;
    width: 340px;
    overflow-y: auto;
  }
  &-noticeTitle {
    font-weight: bold;
    margin-bottom: 8px;
  }
  &-closeIcon{
    position: absolute;
    right:5px;
    top:5px;
  }
  &-noticeMessage {
    font-size: $font-size-sm;
    color: $font-color-secondary;
  }
  &-loaderMore{
     width: 120px;
     text-align: center;
     margin: 0 auto 12px;
     color: rgba(0, 0, 0, 0.6);
     &:hover{
      cursor: pointer;
      color: rgba(0, 0, 0, 1);
     }
  }
  &-optButton{
    text-align: center;
    display: flex;
    justify-content: space-between;
    height: 35px;
    padding-top: 12px;
    align-items: center;
    a{
      color: rgba(0, 0, 0, 0.6);
      display: flex;
      i{
        height: 20px;
        width: 20px;
        margin-right: 5px;
        background: url('../assets/set.png') no-repeat;
        background-size: cover;
      }
    }
    a:hover{
      color: rgba(0, 0, 0, 1);
    }
    a:nth-child(2){
      margin-right: 5px;
    }
    border-top: 2px solid #e6e6e6
  }
  &-noticeEmpty {
    width: 300px;
    text-align: center;
  }
  &-emptyTop{
    text-align:left;
    padding-bottom: 12px;
    font-size:15px;
    font-weight: 900;
    border-bottom: 1px solid #e3e3e3;
  }
  &-emptyCent{
    height:200px;
    img{
      height: 80px;
      margin-top: 30px;
    }
    div{
      margin-top:20px;
      padding-bottom: 50px;
      border-bottom: 1px solid #e3e3e3;
      color: rgba(0, 0, 0, 0.4);
    }
  }
  &-emptyBottom{
    display: flex;
    justify-content: space-between;
    height: 35px;
    padding-top: 12px;
    align-items: center;
    a{
      color: rgba(0, 0, 0, 0.6);
      display: flex;
      i{
        height: 20px;
        width: 20px;
        margin-right: 5px;
        background: url('../assets/set.png') no-repeat;
        background-size: cover;
      }
    }
    a:hover{
      color: rgba(0, 0, 0, 1);
    }
  }
  &-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    margin: 48px 0 24px;
    color: $font-color-secondary;
  }
  &-userMenuItem {
    min-width: 160px;
  }
  &-userMenuIcon {
    margin-right: 12px;
  }
  &-pageHeader {
    padding: 16px 25px 0;
    // background: $grey-1;
    // border-bottom: 1px solid $shadow-color-light;
  }
  &-pageTitle {
    font-size: $font-size-xl;
    margin-bottom: 16px;
  }
  &-breadcrumb {
    margin-bottom: 16px;
  }
  .react-sider-logo {
    height: 32px;
    width: 32px;
    margin-right: 12px;
  }

}


.ant-table-tbody > tr > td {
  word-break: break-word;
}
